<template>
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">{{t('10030')}}</i>
			</a>
			<a @click="openShare" class="nav__btn nav__btn--map">
				<!--<i class="icon icon-share--white"></i>-->
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<Loading v-if="feed === undefined"></Loading>
	<template v-else>
		<div id="container" class="container">
			<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
			<div id="sub" class="sub feed">
				<!-- gallery -->
				<section class="gallery">
					<div class="gallery__slider">
						<swiper v-if="feed.images.length > 1" @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1" :autoplay='{"delay": 3000, "disableOnInteraction": false}' :loop="true">
							<swiper-slide v-for="image in feed.images" :key="image.SEQ">
								<div class="gallery__imgbox noneimage">
									<div class="imagebg" :style="{'background-image': 'url(\'' + image.IMG_URL + '\')'}" />
								</div>
							</swiper-slide>
						</swiper>
						<swiper v-else @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1" :autoplay='{"delay": 3000, "disableOnInteraction": false}' :loop="false">
							<swiper-slide v-for="image in feed.images" :key="image.SEQ">
								<div class="gallery__imgbox noneimage">
									<div class="imagebg" :style="{'background-image': 'url(\'' + image.IMG_URL + '\')'}" />
								</div>
							</swiper-slide>
						</swiper>
					</div>
					<div class="box">
						<div class="gallery__meta gallery__meta--view">
							<span class="gallery__badge">
								<strong class="gallery__currentnum gallery__currentnum--1">1</strong>
								<i class="gallery__dot"></i>
								<small>{{feed.images.length}}</small>
							</span>
						</div>
					</div>
				</section>
				<!-- contact -->
				<section class="content">
					<div class="box">
						<div class="detail__wrap">
							<!-- 피드 기본정보 -->
							<header class="detail__header">
								<div class="detail__title">
									<h2 class="title">{{feed.FEED_NM}}</h2>
									<h3 class="subtitle">
										{{feed.FEED_SUB}}
									</h3>
								</div>
							</header>
							<!-- 피드 상세정보 -->
							<article class="feed-detail">
								<div class="feed-detail__location">
									{{feed.FEED_LOCTEXT}}
								</div>
								<div class="feed-detail__tag">
									{{feed.HASH_TAG}}
								</div>
								<div class="feed-detail__way">
									{{feed.FEED_DESC}}
								</div>
								<ul class="feed-detail__list">
									<li>
										<span>{{t('10078')}}</span>
										{{feed.FEED_ADDR}}
									</li>
								</ul>
							</article>
						</div>
					</div>
				</section>
				<!-- //content -->
			</div>
		</div>
		<!-- navigation -->
		<div id="navigation" class="navigation navigation--noborder">
			<div class="navigation-button">
				<button type="button" class="btn btn-confirm" @click="handleOpenAround">
					{{t('10079')}}
				</button>
				<ul class="item__menu">
					<li>
						<button type="button" class="btn-good" @click="pickFeed">
							<!-- [D] 좋아요 체크된 상태일때 icon-good--point 클래스 추가 -->
							<i class="icon icon-good icon-good" :class="picked && 'icon-good--point'">{{t('10080')}}</i>
							<span>{{likeCnt}}</span>
						</button>
					</li>
				</ul>
			</div>
		</div>
		<!-- //navigation -->
	</template>
	<Feed v-if="openAround" :handleClose="handleCloseAround" :feedNo="feedNo"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Feed from "@/components/layers/Feed";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	components: {
		Feed
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const userData = computed(() => store.state.userData);
		const openAround = ref(false);
		const picked = ref(false);
		const likeCnt = ref(0);
		const { t }= useI18n() //번역필수 모듈

		onMounted(() => {
			store.dispatch("mfd01/fetchMfd0102", {feed_no: route.params.id, mem_id: userData.value.mem_token })
			.then(() => {
				picked.value = store.state.mfd01.mfd0102[route.params.id].MYFEED_YN === "Y";
				likeCnt.value = store.state.mfd01.mfd0102[route.params.id].GOOD_CNT;
			});
		})
		
		const setSlideCurrentIndex = (swiper) => {
			const target = swiper.$el[0].getAttribute('data-currentnum'),
				index = swiper.realIndex;
			document.querySelector(target).innerHTML = index + 1;
		}

		const pick =  () => {
			store.dispatch("mfd01/fetchMfd0104", {feed_no: route.params.id, mem_id: userData.value.mem_token, proc_cd: '01' });
			picked.value = true;
			likeCnt.value += 1;
		}

		const unpick = () => {
			store.dispatch("mfd01/fetchMfd0104", {feed_no: route.params.id, mem_id: userData.value.mem_token, proc_cd: '02' });
			picked.value = false;
			likeCnt.value -= 1;
		}

		const pickFeed = () => {
			if(picked.value) {
				unpick();
			} else {
				pick();
			}
		}

		onBeforeRouteLeave(() => {
			sessionStorage.setItem("nowFeed", route.params.id);
			return true;
		})

		return {
			setSlideCurrentIndex,
			goBack: () => {
				router.back();
			},
			feed: computed(() => store.state.mfd01.mfd0102[route.params.id]),
			picked,
			likeCnt,
			openAround,
			pickFeed,
			handleOpenAround: () => {
				openAround.value = true;
			},
			handleCloseAround: () => {
				openAround.value = false;
			},
			feedNo: computed(() => route.params.id),
			openShare: () => {
				store.dispatch("app/requestShare");
			},
			t,
			i18n,
		}
	},
}
</script>